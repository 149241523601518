var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"installationSlaList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.installationSlas),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.installationSlas),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"nameTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(!_vm.authenticationHasRole('scope_staff'))?_c('p',{style:(("padding-left: " + (props.dataItem.isParent ? '0px' : '15px') + ";"))},[_vm._v(" "+_vm._s(props.dataItem.name)+" ")]):_c('a',{staticClass:"alt-primary-color",style:(("padding-left: " + (props.dataItem.isParent ? '0px' : '15px') + ";")),attrs:{"href":_vm.getWeeklyUrl(props.dataItem.serviceId),"target":"_blank"}},[_vm._v(" "+_vm._s(props.dataItem.name)+" ")])])]}},{key:"okTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.okTimeText != '0d 0h 0m')?_c('p',{style:("color: #34af67;")},[_vm._v(" "+_vm._s(props.dataItem.okTimeText)+" ")]):_vm._e()])]}},{key:"problemTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.problemTimeText != '0d 0h 0m')?_c('p',{style:("color: #d64e4e;")},[_vm._v(" "+_vm._s(props.dataItem.problemTimeText)+" ")]):_vm._e()])]}},{key:"problemTimeTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticStyle:{"display":"flex"}},[(!_vm.authenticationHasRole('scope_staff'))?_c('div',{staticClass:"progress",staticStyle:{"width":"100%","margin-top":"3px"},attrs:{"title":"Only the last 20% of the indicator is displayed."}},[_c('div',{staticClass:"progress-bar",style:(("width: " + (_vm.getPercetage(props.dataItem.sla)) + "%;")),attrs:{"role":"progressbar","aria-valuenow":_vm.getPercetage(props.dataItem.sla),"aria-valuemin":"0","aria-valuemax":"100"}},[_c('div',{staticClass:"progressTitle",staticStyle:{"justify-content":"space-between"}},[_c('p',{staticClass:"ml-1",staticStyle:{"margin-bottom":"0px"}},[_vm._v(" 80% ")]),_c('p',{staticClass:"mr-1",staticStyle:{"margin-bottom":"0px"}},[_vm._v(" 100% ")])])])]):_c('a',{staticClass:"progress",staticStyle:{"width":"100%","margin-top":"3px"},attrs:{"href":_vm.getGraphUrl(props.dataItem.serviceId),"target":"_blank","title":"Only the last 20% of the indicator is displayed."}},[_c('div',{staticClass:"progress-bar",style:(("width: " + (_vm.getPercetage(props.dataItem.sla)) + "%;")),attrs:{"role":"progressbar","aria-valuenow":_vm.getPercetage(props.dataItem.sla),"aria-valuemin":"0","aria-valuemax":"100"}},[_c('div',{staticClass:"progressTitle",staticStyle:{"justify-content":"space-between"}},[_c('p',{staticClass:"ml-1",staticStyle:{"margin-bottom":"0px"}},[_vm._v(" 80% ")]),_c('p',{staticClass:"mr-1",staticStyle:{"margin-bottom":"0px"}},[_vm._v(" 100% ")])])])]),_c('p',{staticClass:"ml-1",style:(("color: " + ((props.dataItem.sla > props.dataItem.goodSla) || props.dataItem.sla == '100.0000' ? '#34af67' : '#d64e4e') + ";"))},[_vm._v(" "+_vm._s(_vm.getProblemSla(props.dataItem.sla))+" ")])])])]}},{key:"slaTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('div',{staticStyle:{"display":"flex"}},[_c('p',{style:(("color: " + (props.dataItem.sla > props.dataItem.goodSla || props.dataItem.sla == '100.0000'? '#34af67' : '#d64e4e') + ";"))},[_vm._v(" "+_vm._s(props.dataItem.sla + " ")+" ")]),_vm._v(" "+_vm._s("\u00A0/\u00A0" + props.dataItem.goodSla)+" ")])])]}},{key:"nameFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"nameFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a Name"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('nameFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }