<template>
  <div class="installationSlaList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(installationSlas)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(installationSlas)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="nameTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <p
              v-if="!authenticationHasRole('scope_staff')"
              :style="`padding-left: ${ props.dataItem.isParent ? '0px' : '15px' };`"
            >
              {{ props.dataItem.name }}
            </p>
            <a
              v-else
              :href="getWeeklyUrl(props.dataItem.serviceId)"
              target="_blank"
              class="alt-primary-color" 
              :style="`padding-left: ${ props.dataItem.isParent ? '0px' : '15px' };`"
            >
              {{ props.dataItem.name }}
            </a>
          </td>
        </template>
        <template
          slot="okTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <p
              v-if="props.dataItem.okTimeText != '0d 0h 0m'"
              :style="`color: #34af67;`"
            >
              {{ props.dataItem.okTimeText }}
            </p>
          </td>
        </template>
        <template
          slot="problemTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <p
              v-if="props.dataItem.problemTimeText != '0d 0h 0m'"
              :style="`color: #d64e4e;`"
            >
              {{ props.dataItem.problemTimeText }}
            </p>
          </td>
        </template>
        <template
          slot="problemTimeTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div style="display:flex;">
              <div
                v-if="!authenticationHasRole('scope_staff')"
                class="progress"
                style="width: 100%; margin-top: 3px;"
                title="Only the last 20% of the indicator is displayed."
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="`width: ${getPercetage(props.dataItem.sla)}%;`"
                  :aria-valuenow="getPercetage(props.dataItem.sla)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progressTitle"
                    style="justify-content: space-between;"
                  >
                    <p
                      class="ml-1"
                      style="margin-bottom: 0px;"
                    >
                      80%
                    </p>
                    <p
                      class="mr-1"
                      style="margin-bottom: 0px;"
                    >
                      100%
                    </p>
                  </div>
                </div>
              </div>
              <a
                v-else
                :href="getGraphUrl(props.dataItem.serviceId)"
                target="_blank"
                class="progress"
                style="width: 100%; margin-top: 3px;"
                title="Only the last 20% of the indicator is displayed."
              >
                <div
                  class="progress-bar"
                  role="progressbar"
                  :style="`width: ${getPercetage(props.dataItem.sla)}%;`"
                  :aria-valuenow="getPercetage(props.dataItem.sla)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div
                    class="progressTitle"
                    style="justify-content: space-between;"
                  >
                    <p
                      class="ml-1"
                      style="margin-bottom: 0px;"
                    >
                      80%
                    </p>
                    <p
                      class="mr-1"
                      style="margin-bottom: 0px;"
                    >
                      100%
                    </p>
                  </div>
                </div>
              </a>
              <p
                class="ml-1"
                :style="`color: ${ (props.dataItem.sla > props.dataItem.goodSla) || props.dataItem.sla == '100.0000' ? '#34af67' : '#d64e4e' };`"
              >
                {{ getProblemSla(props.dataItem.sla) }}
              </p>
            </div>
          </td>
        </template>
        <template
          slot="slaTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div style="display:flex;">
              <p :style="`color: ${ props.dataItem.sla > props.dataItem.goodSla || props.dataItem.sla == '100.0000'? '#34af67' : '#d64e4e' };`">
                {{ props.dataItem.sla + "&nbsp;" }} 
              </p>
              {{ "\u00A0/\u00A0" + props.dataItem.goodSla }}
            </div>
          </td>
        </template>
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a Name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { mapGetters } from 'vuex';

export default {
  name: "InstallationSlaList",
  mixins: [
    authenticationMixin,
    kendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    quartal: {
      type: String,
      required: false,
      default: null
    },
    year: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      installationSlas: null,
      kgm_columns: [
        {
          field: 'name',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "nameFilter",
          cell: 'nameTemplate'
        },
        {
          field: 'okTimeText',
          filterable: false,
          filter: 'text',
          title: 'OK',
          cell: 'okTemplate',
          hideOn: ['mdDown']
        },
        {
          field: 'problemTimeText',
          filterable: false,
          filter: 'text',
          title: 'Problems',
          cell: 'problemTemplate',
          hideOn: ['mdDown']
        },
        {
          field: 'problemTimeText',
          filterable: false,
          filter: 'text',
          title: 'Problem Time',
          cell: 'problemTimeTemplate',
          hideOn: ['xsDown']
        },
        {
          field: 'sla',
          filterable: false,
          filter: 'text',
          title: 'SLA / Acceptable SLA',
          cell: 'slaTemplate'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'store_getEnvironment'
    ]),
  },
  watch: {
    year () {
      this.getInstallationSlas();
    },
    quartal () {
      this.getInstallationSlas();
    }
  },
  created () {
    this.getInstallationSlas();
  },
  methods: {
    getWeeklyUrl (serviceId) {
      if(this.store_getEnvironment && this.store_getEnvironment.toLowerCase() === 'production') {
        return `https://admin.skiline.cc/zabbix/zabbix.php?action=report.services&serviceid=${serviceId}&period=weekly`;
      }
      return `https://zabbix.staging.lakeside.skiline.cc/zabbix.php?action=report.services&serviceid=${serviceId}&period=weekly`;
    },
    getGraphUrl (serviceId) {
      if(this.store_getEnvironment && this.store_getEnvironment.toLowerCase() === 'production') {
        return `https://admin.skiline.cc/zabbix/srv_status.php?serviceid=${serviceId}&showgraph=1`
      }
      return `https://zabbix.staging.lakeside.skiline.cc/srv_status.php?serviceid=${serviceId}&showgraph=1`
    },
    getPercetage (val) {
      var percentage = 100 * (100 - val) / 20;
      return Math.abs(100 - percentage);
    },
    getProblemSla (val) {
      return (100 - val).toFixed(4);
    },
    getInstallationSlas () {
      this.loading = true;
      this.axios.get(`/MonitoringSystem/GetInstallationServiceSla?installationId=${ this.installationId }&quartal=${ this.quartal }&year=${ this.year }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.installationSlas = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.installationSlaList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
.installationSlaList .progress .progress-bar {
  background-color: #34af67;
}

.installationSlaList .progress {
  background-color: #d64e4e;
}

.installationSlaList .progress .progress-bar .progressTitle {
  display: none;
}

.installationSlaList .progress:hover .progress-bar .progressTitle {
  display: flex;
}
</style>